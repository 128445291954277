export const PORTATION_ALLOWANCE_CODE = {
  SUCCESS: 0,
  TECH_ERROR: 1,
  ALREADY_BEE_CTN: 2,
  ALREADY_EXISTS: 3,
  REGIONS_DO_NOT_MATCH: 4,
  NEED_REDIRECT: 5,
};

export const PORTATION_ALLOWANCE_ERROR = {
  [PORTATION_ALLOWANCE_CODE.ALREADY_BEE_CTN]: 'mnpErrorBeeCTN',
  [PORTATION_ALLOWANCE_CODE.ALREADY_EXISTS]: 'mnpErrorAlreadyExists',
  [PORTATION_ALLOWANCE_CODE.TECH_ERROR]: 'mnpErrorTech',
};

export const NUMBER_TYPE = {
  PORTATION: 'portationNumber',
  CHOSEN: 'chosenNumber',
};

export const PORTATION_NUMBER_STATUS = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  WRONG_REGION: 'WRONG_REGION',
  UNKNOWN: 'UNKNOWN',
};

export const PORTATION_NUMBER_DEFAULT_VALUE = {
  value: '',
  status: PORTATION_NUMBER_STATUS.UNKNOWN,
  isFromQuery: false,
  discount: 0,
  price: 0,
  redirectUrl: '',
};

export const PACKAGES_TO_DISPLAY_ALIASES = ['internet', 'phone', 'sms'];

// Ключ get параметра для перехода к быстрой покупки esim из каталога
export const GET_TO_BASKET = 'toBasket';
// Ключ get параметра для установки выбранного тарифа из каталога для быстрой покупки
export const GET_DPC_ID = 'dpcId';
