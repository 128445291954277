import { useWillMount } from '@beef/react';
import { clientOnlyHook } from '@beef/utils';
import type { IHeaderCommon } from '@carnica/smart-kit';
import { prepareChatStore, prepareDevicesStore, prepareLoginStore } from '@beef/layout-kit/store';
import { mapDevicesPayload } from '@beef/layout-kit/utils';

import {
  SET_AUTH_DATA,
  SET_CURRENT_CTN,
  SET_REGION,
  SET_USER,
} from 'store-legacy-actions/actionTypes';
import store from 'store';
import { setInterceptors } from 'utils/interceptorsHandler';

const _useLegacyHeaderActions = (data: IHeaderCommon): void => {
  useWillMount(() => {
    prepareChatStore();
    // @ts-expect-error FIXME Header React Model types Or Remove this code
    prepareLoginStore(data.loginModel);
    // @ts-expect-error FIXME  Remove this code ???
    prepareDevicesStore(mapDevicesPayload(data));

    store.dispatch({
      type: SET_REGION,
      // @ts-expect-error FIXME Header React Model types Or Remove this code
      payload: data.currentRegion?.userRegion || data.currentRegion,
    });
    // @ts-expect-error FIXME Header React Model types Or Remove this code
    store.dispatch({ type: SET_CURRENT_CTN, payload: data.currentAccount });
    store.dispatch({ type: SET_USER, payload: data?.auth?.user });
    store.dispatch({
      type: SET_AUTH_DATA,
      payload: {
        profileUrl: data?.auth?.profileUrl,
      },
    });
    setInterceptors();
  });
};

export const useLegacyHeaderActions = clientOnlyHook(_useLegacyHeaderActions);
