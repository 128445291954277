import { SET_USER } from '../legacy-actions/actionTypes';

// eslint-disable-next-line default-param-last
export const setUser = (state = {}, action) => {
  if (action.type === SET_USER) {
    return { ...state, user: action.payload };
  }

  return state;
};
